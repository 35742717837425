<template>
    <div class="dash-container">
        <h1>
        INVITE
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="search-bar">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
             <ValidationProvider rules="required|email" name="email" v-slot="{ errors, valid }">
              <b-field label="Email Address:" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input type="text" maxlength="50" onkeyup='this.value = this.value.toLowerCase();' v-model="email"></b-input> 
                <button class="button invite-button is-primary is-outlined" @click="handleSubmit(invite)">
                    <span>Invite</span>
                </button>
              </b-field>
             </ValidationProvider>
          </ValidationObserver>
        </div>
        <br>
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="search-results"><router-view></router-view></div>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    props: {
      mail: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        email: this.mail
      }
    },
    methods: {
      invite() {
        var next = "/dashboard/invite/results/" + this.email
        this.$router.replace(next);
      }
    }
}
</script>
<style lang='scss' scoped>
h1 {
  font-weight: bold;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.search-bar {
  padding-top: 30px;
}
.invite-button {
  margin-top: 0px;
  text-align: center;
  width: 100px;
}
</style>